import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Projects = () => (
  <Layout linkColor="#5388ff"  language="fr">
    <SEO title="Projects" />
    <div className="wavy">
    <div className="paddedBody grayBody">
    <h1>Projets</h1>
    <h2>Projet universel de données médicales</h2>
    <p>Les données médicales sont au milieu d’un tir à la corde. Si on arrive à rendre accessible certaines données médicales on peut sauver beaucoup de vies. La combinaison de données médicales avec des informations démographiques qui y correspondent peut conduire à des progrès inouïs dans la compréhension des causes et des traitements potentiels d’un grand nombre de maladies. Si des données génétiques y sont ajoutées, les percées que nous pourrions faire en matière de traitements et prévention sont énormes.</p>
    <p>De l’autre côté, il y a l’obligation de protéger les données privées des gens. Il faut s’assurer que des gens avec des intentions néfastes n’arrivent jamais à avoir accès à ces donnés-là.</p>
    <p>Nous pensions que, en tant que société, il nous restait plusieurs compromis complexes à faire, qu’il fallait choisir soit plus de sécurité, soit plus d’accessibilité. En conséquence, nous n'obtenions ni l’accessibilité désirée, ni la sécurité souhaitée.</p>
    <p>En utilisant des registres Blockchain, Jonar Labs a développé un prototype d’un mécanisme qui peut rendre les données facilement disponibles en offrant également une grande protection des données individuelles. Nous l'avons conçu comme système de stockage très sécurisé auquel tout système de données médicales peut facilement accéder.</p>
    <p>Par la suite, nous avons cherché à y ajouter des génomes humains. Nous avons pu ajouter ces données de taille immense à notre système de stockage décentralisé. Notre technologie nous permet de traiter et de fournir ces données de manière efficace et rapide, du jamais vu en matière de données de génomes humains.</p>
    <h2>Guichet Bitcoin</h2>
    <p>Chez Jonar Labs, nous pensons que les technologies décentralisées peuvent aider le monde à devenir plus démocratique et égalitaire. Nous voulons lancer des produits dans qui renforcent la confiance à l’égard des technologies décentralisées. Nous avons commencé avec un échange de cryptomonnaies (Bitcoin et Ethereum).</p>
    <p>Nous comprenons qu'il y a eu beaucoup de désinformation quant aux devises basées sur la blockchain. D'une part, la blockchain Bitcoin est incroyablement sécuritaire, beaucoup plus sécuritaire que n'importe quel autre mécanisme de stockage de valeur, certainement plus sécuritaire que les banques. Le problème est que certains échanges bitcoin stockent des informations avec les clés privées dans des bases de données centralisées qui ont été piratées. Nous avons créé un service qui ne stocke aucune de ces informations-la. Nous voulions créer un produit qui exploite la puissance de la blockchain Bitcoin, rien d’autre, et c’est ce que nous avons fait.</p>
    <p>Plus d'informations, rendez vous sur: <a href="https://www.guichetbitcoin.com">https://www.guichetbitcoin.com</a></p>
    <h2>Blockchain en un clic</h2>
    <p>Certaines idées sociales, qui étaient autrefois vus comme n’étant que des expériences de pensée,  sont maintenant réalisables grâce à la Blockchain. Des économistes tels que Smith, Cournot ou Nash n'auraient jamais pu envisager un outil suffisamment puissant pour tester et valider des concepts de la théorie des jeux dans le monde réel. Les mécanismes de consensus des blockchains peuvent être parmi les meilleurs outils de collaboration sociétale au 21e siècle. Le problème est qu'il est encore incroyablement difficile de lancer facilement des blockchains. Avec le projet One Click Blockchain, Jonar Labs est présentement en train de développer un système pour déployer des nœuds Hyperledger en un seul clic. Dans le cadre de ce projet nous allons offrir également la possibilité de personnaliser des algorithmes de consensus et de tester et déployer des blockchains beaucoup plus facilement qu’auparavant.</p>
    <p>Plus d'informations, rendez vous sur: <a href="https://www.youtube.com/watch?v=emK0MwriRhw&t=1s">https://www.youtube.com/watch?v=emK0MwriRhw&t=1s</a></p>
  </div></div>
  </Layout>
)

export default Projects
